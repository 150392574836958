@keyframes glow {
  from {
    box-shadow: 0 0 10px #0c64c1, 0 0 20px #0c64c1, 0 0 30px #0c64c1,
      0 0 40px #0c64c1;
  }
  to {
    box-shadow: 0 0 20px #0c64c1, 0 0 30px #0c64c1, 0 0 40px #0c64c1,
      0 0 50px #0c64c1;
  }
}

/* .avatar-glow {
    animation: glow 2s infinite;
  } */

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px #04d9ff, 0 0 10px #04d9ff, 0 0 15px #04d9ff,
      0 0 20px #04d9ff;
  }
  10% {
    box-shadow: 0 0 10px #04d9ff, 0 0 20px #04d9ff, 0 0 30px #04d9ff,
      0 0 40px #04d9ff;
  }
  20% {
    box-shadow: 0 0 20px #04d9ff, 0 0 30px #04d9ff, 0 0 40px #04d9ff,
      0 0 50px #04d9ff;
  }
  30% {
    box-shadow: 0 0 30px #04d9ff, 0 0 40px #04d9ff, 0 0 50px #04d9ff,
      0 0 60px #04d9ff;
  }
  40% {
    box-shadow: 0 0 40px #04d9ff, 0 0 50px #04d9ff, 0 0 60px #04d9ff,
      0 0 70px #04d9ff;
  }
  50% {
    box-shadow: 0 0 50px #04d9ff, 0 0 60px #04d9ff, 0 0 70px #04d9ff,
      0 0 80px #04d9ff;
  }
  60% {
    box-shadow: 0 0 60px #04d9ff, 0 0 70px #04d9ff, 0 0 80px #04d9ff,
      0 0 90px #04d9ff;
  }
  70% {
    box-shadow: 0 0 70px #04d9ff, 0 0 80px #04d9ff, 0 0 90px #04d9ff,
      0 0 100px #04d9ff;
  }
  80% {
    box-shadow: 0 0 80px #04d9ff, 0 0 90px #04d9ff, 0 0 100px #04d9ff,
      0 0 110px #04d9ff;
  }
  90% {
    box-shadow: 0 0 90px #04d9ff, 0 0 100px #04d9ff, 0 0 110px #04d9ff,
      0 0 120px #04d9ff;
  }
  100% {
    box-shadow: 0 0 100px #04d9ff, 0 0 150px #04d9ff, 0 0 200px #04d9ff,
      0 0 250px #04d9ff;
  }
}

.avatar-glow:hover {
  animation: glowing 2s infinite;
}

.avatar-glow {
  box-shadow: 0 0 5px #0c64c1, 0 0 10px #0c64c1;
}

/* .avatar-glow:hover {
    animation: glowing 2s infinite;
  } */

.avatar-glow.notification {
  box-shadow: 0 0 5px #ff04d9, 0 0 10px #ff04d9;
  animation: glowing 1s infinite;
}

/* <div className={`some-other-class ${condition ? 'avatar-glow' : ''}`}>

</div>

<div className="some-other-class avatar-glow">

</div> */
