.root {
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.customScrollBar {
  /* width */
  &::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #99adda /*#888*/;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #99adda /*#555*/;
  }
}

//

.nc-will-change-top {
  will-change: top;
}
//

.nc-SectionClientSay .glide__bullet--active {
  @apply bg-neutral-700;
}
.nc-GallerySlider .glide__bullet--active {
  @apply bg-white w-2 h-2;
}

//
.nc-card-title {
  @apply transition-colors  hover:text-primary-900 dark:hover:text-primary-300 duration-75;
}

//
.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}

//
.nc-box-has-hover {
  @apply bg-white rounded-3xl border border-neutral-200/70 hover:bg-neutral-50 hover:shadow-xl hover:border-transparent transition-shadow;
}

.nc-dark-box-bg-has-hover {
  @apply dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:hover:bg-neutral-800 dark:hover:shadow-2xl;
}

.nc-dark-box-bg {
  @apply dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:shadow-2xl;
}

//
.nc-origin-100 {
  transform-origin: 100% 50% 0px;
}
.nc-origin-50 {
  transform-origin: 50% 50% 0px;
}

// HERO
.nc-hero-field-padding {
  @apply py-3 px-4 md:py-4 md:px-6 xl:py-6 xl:px-8;
}
.nc-hero-field-focused {
  @apply shadow-2xl rounded-full dark:bg-white/5;
}

.nc-flex-1 {
  flex: 1 0 0%;
}

.nc-flex-1\.5 {
  flex: 1.5 0 0%;
}
.nc-flex-1\.5-auto {
  flex: 1.5 0 auto;
}

.nc-flex-2 {
  flex: 2 0 0%;
}

.nc-flex-2-auto {
  flex: 2 0 auto;
}

.nc-flex-2\.5 {
  flex: 2.5 0 0%;
}

@screen lg {
  .lg\:nc-flex-1\.5 {
    flex: 1.5 0 0%;
  }
  .lg\:nc-flex-1\.5-auto {
    flex: 1.5 0 auto;
  }

  .lg\:nc-flex-2 {
    flex: 2 0 0%;
  }

  .lg\:nc-flex-2-auto {
    flex: 2 0 auto;
  }

  .lg\:nc-flex-2\.5 {
    flex: 2.5 0 0%;
  }
}

.nc-icon-field {
  @apply w-5 h-5 lg:w-7 lg:h-7;
}
//
button.gm-control-active.gm-fullscreen-control {
  @apply rounded-xl #{!important};
}

//
.nc-FiveStartIconForRate {
  svg:hover ~ svg {
    @apply text-neutral-300;
  }
}

// LISTING PAGE DETAIL
.listingSection__wrap {
  @apply w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8;
}

//
input[type="time"]::-webkit-calendar-picker-indicator {
  @apply dark:bg-neutral-500 rounded-md;
}

[type="button"] {
  -webkit-appearance: none !important;
}

//
.nc-CardLarge1--hasAnimation {
  .nc-CardLarge1__left {
    animation-name: CardLarge1ExampleLeft;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
  }
  .nc-CardLarge1__right {
    animation-name: CardLarge1ExampleRight;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
  }
}

@keyframes CardLarge1ExampleRight {
  from {
    transform: scale(1.05) translateY(20px);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
@keyframes CardLarge1ExampleLeft {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
