/* For Webkit browsers like Chrome and Safari */
::-webkit-scrollbar-track {
    background-color: transparent;   border-radius: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #55617c /* rgba(0, 0, 0, 0.5)*/;
    border-radius: 0px;
  }
  ::-webkit-scrollbar {
    width: px;
  }
  
  /* For Firefox */
  /* No reliable way to make the scrollbar "floating" as of now */


  .divScroll {
    overflow-x: hidden;
  }