@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

.hide-logged-in-children .logged-in {
  display: none;
}

.hide-logged-out-children .logged-out {
  display: none;
}

.footer-contact-details {
  max-width: 24rem;
}

#blocks-container {
  overflow-x: scroll;
  overflow-y: hidden;
}

th {
  border: 1px solid rgb(95, 95, 95);
  font-weight: 500;
  padding: 0.5rem;
}

tr > th:first-of-type {
  border-top-left-radius: 0.5rem;
}

tr > th:not(:last-of-type),
tr > td:not(:last-of-type) {
  border-right: 0;
}

tr > th:last-of-type {
  border-top-right-radius: 0.5rem;
}

tbody > tr > td {
  border-top: 0;
}

tbody > tr:last-of-type > td:first-of-type {
  border-bottom-left-radius: 0.5rem;
}

tbody > tr:last-of-type > td:last-of-type {
  border-bottom-right-radius: 0.5rem;
}

.recommendations-container {
  z-index: 2;
}

.refer-a-friend-modal,
.share-modal,
.sticky-panel {
  z-index: 9999;
}

.paypal-buttons {
  z-index: 0;
}

.react-datalist-input__container {
  z-index: 999;
}

.react-datalist-input__textbox {
  display: none;
}

.react-datalist-input__listbox {
  border-radius: 0 10px 10px 0;
  overflow-y: scroll;
  max-height: 150px;
}

.datalist-item {
  cursor: pointer;
  border: 1px solid black;
  border-top: 0;
}

.react-datalist-input__listbox-option:first-child > .datalist-item {
  border-radius: 10px 10px 0 0;
  border-top: 1px solid black;
}

.react-datalist-input__listbox-option:last-child > .datalist-item {
  border-radius: 0 0 10px 10px;
}

.react-datalist-input__listbox-option:only-child > .datalist-item {
  border-radius: 10px;
}

.datalist-item:hover {
  background-color: rgb(224, 224, 224);
}

.max-w-560 {
  max-width: 560px;
}

.glow-text:hover {
  text-shadow: 0 0 10px #00e600, 0 0 20px #00e600, 0 0 30px #00e600;
}

@media (min-width: 1024px) {
  .runner-height {
    height: calc(100vh - 120px);
  }
  .blocks-height {
    height: calc(100vh - 220px);
  }
}

@layer components {
  .shadow-red {
    box-shadow: 0 4px 14px 0 rgba(255, 0, 0, 1);
  }
  .shadow-blue {
    box-shadow: 0 4px 14px 0 rgba(0, 0, 255, 1);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animate-fade-in-out {
  animation: fadeInOut 1s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 1s ease-in-out forwards;
}

.border-animated {
  border: 2px solid green;
  animation: border-animation 2s forwards;
}

@keyframes border-animation {
  0% {
    border-color: green;
  }
  50% {
    border-color: lightgreen;
  }
  100% {
    border-color: green;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-10px);
  }
  20% {
    transform: translateX(10px);
  }
  30% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(10px);
  }
  70% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  90% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.react-datalist-input__textbox {
  color: "transparent";
}

.animate-shake {
  animation: shake 2s ease-in-out;
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

::-webkit-scrollbar-track {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #99adda;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #6b7999;
}

/* Tailwind utility class to hide scroll bar */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.menu-container {
  @apply w-1/5 h-full rounded-lg px-4 py-2 gap-2 flex items-center flex-col;
  @apply sm:w-1/3 md:w-1/4 lg:w-1/5 xl:w-1/6;
}

.menu-hidden {
  @apply hidden md:block;
}

/* Mikes CSS */

.creator {
}

#insert-reference-btn {
  margin: 6px 0;
}

h3.tool-name {
  display: none;
  color: #4466b0;
  font-family: "Roboto", Sans-serif;
  padding-left: 16px;
  margin-top: 0;
}
.toolName {
  text-align: right;
}

legend {
  /*
	  background: #4466b0;
		color: white;
    */
}

.publish-legend {
  margin-bottom: 3px;
}

button[class$="-save-button"] {
  float: right;
}

fieldset textarea,
fieldset input {
  width: 100%;
}

/* fieldset {
    background: cornsilk;
    border-radius: 10px;
    border: solid 3px #57a4e7;
    margin-bottom: 20px;
    padding: 15px 25px;
	border-image: linear-gradient(#1952cf, #b95fe5) 30;
	background-image: linear-gradient(30deg, #1952cfa8, #b95fe582);
	} */
fieldset {
  /*
    background: linear-gradient(30deg, #1952cfa8, #b95fe582);
    */
}

.gap {
  gap: 5px;
}

/* .shared-textarea {
  flex-grow: 1;
  height: 100px;
} */

.prompt-textarea {
  height: 200px;
}
.question-message-input {
  height: 100px;
}

.result-div {
  display: none;
  background-color: #d2a6d2;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 30px 0px;
}

.result-div .result {
  background-color: #a6d0d2;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 30px 0px;
  overflow: auto;
  max-width: 100%;
  max-height: 350px;
}

.result-div .input {
  background-color: #e2c6e2;
  padding: 10px 20px;
  border-radius: 10px;
  margin: 30px 0px;
  max-height: 200px;
  overflow: auto;
}

#reference-controls {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #f0f0f0;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#reference-controls select,
#reference-controls button {
  width: 100%;
}

#blockdiv-question,
#blockdiv-info,
#blockdiv-processing,
#blockdiv-image,
#blockdiv-download {
  display: none;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

div .level-0 {
  border-bottom: 1px solid;
}

div .response-items {
  float: left;
  border: solid black 1px;
  margin: 2px;
  padding: 2px;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  /* width: 100%; */
}

td {
  border: 1px solid grey;
  padding: 10px;
}

.grid-4-cols-max-content {
  grid-template-columns: repeat(4, max-content);
}

.download-options input {
  width: auto;
}
.download-options label {
  display: inline;
}

.freeText {
  font-weight: bold;
  color: #4466b0;
}

@keyframes toms-glow {
  0% {
    box-shadow: 0 0 10px rgb(93, 0, 155);
  }
  50% {
    box-shadow: 0 0 10px 5px rgb(67, 64, 204);
  }
  100% {
    box-shadow: 0 0 10px rgb(93, 0, 155);
  }
}

.animate-toms-glow {
  animation: toms-glow 1.5s infinite alternate;
}

.carousel .slide .legend {
  opacity: 1 !important;
}

/* highlight image carousel arrows on touchscreen devices */
@media (pointer: coarse) { 
  .control-arrow {
    background-color: black !important;
    opacity: 0.3 !important; 
  }
}

.show-arrows .control-arrow {
  background-color: black !important;
  opacity: 0.3 !important; 
}